<!-- 详情页 -->
<template>
  <div class="detail">
    <div class="title">{{ this.list.name }}</div>
    <div class="time">{{ this.list.createDate }}</div>
    <div class="content" v-html="this.list.intro"></div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { noticeApi } from "../../request/api/index";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      id: "",
      initData: [],
      list: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getdata() {
      noticeApi({}).then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.initData = res.data.data;
          for (var i in this.initData) {
            if (this.initData[i].id == this.id) {
              this.list = this.initData[i];
            }
          }
        }
      });
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.id = this.$route.params.id;
    this.getdata();
  },
};
</script>
<style lang="less">
.detail {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 1rem 1rem 0;
  overflow: auto;
  .title {
    width: 100%;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2rem;
    margin: 1rem 0 2rem;
  }
  .time {
    width: 100%;
    text-align: right;
    margin-bottom: 2rem;
  }
  .content {
    width: 100%;
  }
}
</style>